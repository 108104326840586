<template>
  <div id="mian">
    <div v-auth="'SYSTEM:SYSTEM:SAFE'">
      <div>
        <div class="con_from">
          <table class="tableForm" cellspacing="0" cellpadding="0" align="center">
            <tr>
              <td width="151px">绑定手机</td>
              <td width="637px ">{{phone}}</td>
              <td width="240px">
                <a v-auth="'BASIC:SYSTEM:SAFE/EDIT'" href="javascript:;" class="lianjie" @click="modifyPhone">修改</a>
              </td>
            </tr>
            <tr>
              <td>绑定邮箱</td>
              <td>{{userInfo.email|formatEmail}}</td>
              <td>
                <a v-auth="'BASIC:SYSTEM:SAFE/EDITEMAIL'" href="javascript:;" class="lianjie" @click="modifyEmail">修改</a>
              </td>
            </tr>
            <tr>
              <td>安全密码</td>
              <td>修改账户信息或审核退款信息时输入，请妥善保管</td>
              <td>
                <a v-auth="'BASIC:SYSTEM:SAFE/SETAQMI'" href="javascript:;" class="lianjie"
                  @click="handlEditPassword('设置')" v-if="!userInfo.passwordStatus">设置</a>
                <a v-auth="'BASIC:SYSTEM:SAFE/RETRIEVE'" href="javascript:;" class="lianjie"
                  @click="handlEditPassword('找回')" v-if="userInfo.passwordStatus">找回</a>
                <a v-auth="'BASIC:SYSTEM:SAFE/EDITAQMM'"  href="javascript:;" class="lianjie" @click="modifyPassword" v-if="userInfo.passwordStatus">修改</a>
              </td>
            </tr>
            <tr>
              <td>预留信息</td>
              <td>
                <p style="padding:0px 20px;">预留信息是由你自己设置的一段文字，用来鉴定网站身份，防止假冒网站造成损失。配置后将在首页显示。若未看到预留信息，表明当前访问的网站存在风险，请终止操作，并修改密码。</p>
              </td>
              <td>
                <a v-auth="'BASIC:SYSTEM:SAFE/SET'" class="lianjie" @click="reserveSet('设置')" v-if="!userInfo.reservedMsg">设置</a>
                <a v-auth="'BASIC:SYSTEM:SAFE/SHOW'" class="lianjie" @click="reserveSee('查看')" v-if="userInfo.reservedMsg">查看</a>
                <a v-auth="'BASIC:SYSTEM:SAFE/EDITYLXX'" class="lianjie" @click="reserveSet('修改')" v-if="userInfo.reservedMsg">修改</a>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <Modal title="修改绑定手机号" v-model="editPhoneFirst" width="490px"  :close-on-click-modal="false">
        <ul class="overAccount_ul" style="margin-bottom:32px">
          <li class="ul_left">
            <div class="overAccount_ul_title" style="color:#666666">原手机号</div>
            <Input v-model="phone" maxlength="11" placeholder="输入手机号" style="width:235px" disabled></Input>
            <span class="getCode" @click="getOldCode">{{timeOut==0?'获取验证码':`倒计时${timeOut}s`}}</span>
            <div class="overAccount_ul_title" style="color:#666666"><i>*</i>验证码</div>
            <Input v-model="editPhoneFrom.validateCode" maxlength="6" placeholder="输入短信验证码" style="width: 320px;"></Input>
          </li>
        </ul>
        <span slot="footer" class="dialog-footer">
          <Button class="exitBt" @click="editPhoneFirst = false">取 消</Button>
          <Button class="addBt" @click="editPhone" style="width:60px;height:32px">下一步</Button>
        </span>
      </Modal>
      <Modal title="修改绑定手机号" v-model="editPhoneSecond" width="490px"  :close-on-click-modal="false">
        <ul class="overAccount_ul" style="margin-bottom:32px">
          <li class="ul_left">
            <div class="overAccount_ul_title" style="color:#666666"><i>*</i>绑定手机号</div>
            <Input v-model="editPhoneFrom.rebindPhone" maxlength="11" placeholder="输入手机号" style="width:235px"></Input>
            <span class="getCode" @click="getCode(editPhoneFrom.rebindPhone)">{{timeOut==0?'获取验证码':`倒计时${timeOut}s`}}</span>
            <div class="overAccount_ul_title" style="color:#666666"><i>*</i>验证码</div>
            <Input v-model="editPhoneFrom.rebindValidateCode" maxlength="6" placeholder="输入短信验证码" style="width: 320px;"></Input>
            <div class="overAccount_ul_title" style="color:#666666"><i>*</i>安全密码</div>
            <Input v-model="editPhoneFrom.securityPassword" show-password maxlength="6" placeholder="输入安全密码" style="width: 320px;"></Input>
          </li>
        </ul>
        <span slot="footer" class="dialog-footer">
          <Button class="exitBt" @click="editPhoneSecond = false">取 消</Button>
          <Button class="addBt" @click="editPhoneTrue" style="width:60px;height:32px">确 定</Button>
        </span>
      </Modal>
      <Modal title="修改绑定邮箱" v-model="editEmail" width="490px"  :close-on-click-modal="false">
        <ul class="overAccount_ul" style="margin-bottom:32px">
          <li class="ul_left">
            <div class="overAccount_ul_title" style="color:#666666"><i>*</i>原绑定邮箱</div>
            <Input v-model="editEmailFrom.boundEmail" placeholder="输入原绑定邮箱" style="width:320px"></Input>
            <div class="overAccount_ul_title" style="color:#666666"><i>*</i>新绑定邮箱</div>
            <Input v-model="editEmailFrom.rebindEmail" placeholder="输入新绑定邮箱" style="width: 320px;"></Input>
            <div class="overAccount_ul_title" style="color:#666666"><i>*</i>安全密码</div>
            <Input v-model="editEmailFrom.securityPassword" maxlength="6" show-password placeholder="输入安全密码(6位数字)" style="width: 320px;"></Input>
          </li>
        </ul>
        <span slot="footer" class="dialog-footer">
          <Button class="exitBt" @click="editEmail = false">取 消</Button>
          <Button class="addBt" @click="editEmailTrue" style="width:60px;height:32px">确 定</Button>
        </span>
      </Modal>
      <Modal :title="passwordStatus" v-model="resetPassword" width="490px"  :close-on-click-modal="false">
        <ul class="overAccount_ul" style="margin-bottom:32px">
          <li class="ul_left">
            <div class="overAccount_ul_title" style="color:#666666">绑定手机号</div>
            <Input v-model="phone" maxlength="11" placeholder="输入手机号" style="width:235px" disabled></Input>
            <span class="getCode" @click="getOldCode">{{timeOut==0?'获取验证码':`倒计时${timeOut}s`}}</span>
            <div class="overAccount_ul_title" style="color:#666666"><i>*</i>验证码</div>
            <Input v-model="resetPasswordFrom.validateCode" maxlength="6" placeholder="输入短信验证码" style="width: 320px;"></Input>
            <div class="overAccount_ul_title" style="color:#666666"><i>*</i>登录密码</div>
            <Input v-model="resetPasswordFrom.loginPassword" show-password placeholder="输入登录密码" style="width: 320px;"></Input>
            <div class="overAccount_ul_title" style="color:#666666"><i>*</i>设置安全密码</div>
            <Input v-model="resetPasswordFrom.newSecurityPassword" show-password maxlength="6" placeholder="输入安全密码(6位数字)" style="width: 320px;"></Input>
            <div class="overAccount_ul_title" style="color:#666666"><i>*</i>确认安全密码</div>
            <Input v-model="resetPasswordFrom.reNewSecurityPassword" show-password maxlength="6" placeholder="输入确认安全密码(6位数字)" style="width: 320px;"></Input>
          </li>
        </ul>
        <span slot="footer" class="dialog-footer">
          <Button class="exitBt" @click="resetPassword = false">取 消</Button>
          <Button class="addBt" @click="settingPwd" style="width:60px;height:32px">确 定</Button>
        </span>
      </Modal>
      <Modal title="修改安全密码" v-model="editPassword" width="490px"  :close-on-click-modal="false">
        <ul class="overAccount_ul" style="margin-bottom:32px">
          <li class="ul_left">
            <div class="overAccount_ul_title" style="color:#666666">绑定手机号</div>
            <Input v-model="phone" maxlength="11" placeholder="输入手机号" style="width:235px" disabled></Input>
            <span class="getCode" @click="getOldCode">{{timeOut==0?'获取验证码':`倒计时${timeOut}s`}}</span>
            <div class="overAccount_ul_title" style="color:#666666"><i>*</i>验证码</div>
            <Input v-model="editPasswordFrom.validateCode" maxlength="6" placeholder="输入短信验证码" style="width: 320px;"></Input>
            <div class="overAccount_ul_title" style="color:#666666"><i>*</i>原安全密码</div>
            <Input v-model="editPasswordFrom.oldSecurityPassword" show-password maxlength="6" placeholder="输入原安全密码(6位数字)" style="width: 320px;"></Input>
            <div class="overAccount_ul_title" style="color:#666666"><i>*</i>设置安全密码</div>
            <Input v-model="editPasswordFrom.newSecurityPassword" show-password maxlength="6" placeholder="输入安全密码(6位数字)" style="width: 320px;"></Input>
            <div class="overAccount_ul_title" style="color:#666666"><i>*</i>确认安全密码</div>
            <Input v-model="editPasswordFrom.reNewSecurityPassword" show-password maxlength="6" placeholder="输入确认安全密码(6位数字)" style="width: 320px;"></Input>
          </li>
        </ul>
        <span slot="footer" class="dialog-footer">
          <Button class="exitBt" @click="editPassword = false">取 消</Button>
          <Button class="addBt" @click="editPasswordTrue" style="width:60px;height:32px">确 定</Button>
        </span>
      </Modal>
      <!-- 设置/修改预留信息 -->
      <Modal :title="reserveTitle" v-model="reserveInfo" width="490px"  :close-on-click-modal="false">
        <ul class="overAccount_ul" style="margin-bottom:32px">
          <li class="ul_left">
            <div class="overAccount_ul_title" style="color:#666666">手机号</div>
            <Input v-model="phone" maxlength="11" placeholder="输入手机号" style="width:235px" disabled></Input>
            <span class="getCode" @click="getOldCode">{{timeOut==0?'获取验证码':`倒计时${timeOut}s`}}</span>
            <div class="overAccount_ul_title" style="color:#666666"><i>*</i>验证码</div>
            <Input v-model="reserveFrom.validateCode" maxlength="6" placeholder="输入短信验证码" style="width: 320px;"></Input>
            <div class="overAccount_ul_title"><i>*</i>预留信息</div>
            <Input style="width: 320px;" v-model="reserveFrom.reservedMsg"></Input>
          </li>
        </ul>
        <span slot="footer" class="dialog-footer">
          <Button class="exitBt" @click="reserveInfo = false">取 消</Button>
          <Button class="addBt" @click="reservedInfoBtn">确 定</Button>
        </span>
      </Modal>
      <!--查看预留信息 -->
      <Modal title="查看预留信息" v-model="reserveAmendIofo" width="490px"  :close-on-click-modal="false">
        <ul class="overAccount_ul" style="margin-bottom:32px">
          <li class="ul_left">
            <div class="overAccount_ul_title"><i>*</i>预留信息</div>
            <Input style="width: 320px;" v-model="reserveFrom.reservedMsg" disabled></Input>
          </li>
        </ul>
        <span slot="footer" class="dialog-footer">
          <Button class="exitBt" @click="reserveAmendIofo = false">取 消</Button>
          <Button class="addBt" @click="reserveAmendIofo = false" style="width:60px;height:32px">确定</Button>
        </span>
      </Modal>      
    </div>
  </div>
</template>
<script>
  import {
    getValidatecode,
    validatecode,
    getSecurity
  } from '@/api/common.js'
  import {
    editPhone,
    resetPassword,
    editPassword,
    reservedMsgSet,reservedMsgSee,editEamil
  } from '@/api/management/index.js'
  import md5 from 'js-md5';
  export default {
    data() {
      return {
        value: true,
        timeOut: 0,
        codeShow: null,
        editPhoneFirst: false,
        editPhoneSecond: false,
        editEmail: false,
        code: '',
        passwordStatus: '',
        editPassword: false,
        phone: '', // 加密手机号
        isShowEdit: true, // 安全密码显示
        userInfo: '', // 用户信息
        editPhoneFrom:{
          boundPhone:'',
          validateCode:'',
          rebindPhone:'',
          rebindValidateCode:'',
          securityPassword:''
        },//修改原手机
        editEmailFrom:{
          boundEmail:'',
          rebindEmail:'',
          securityPassword:''
        },// 修改邮箱
        resetPassword: false,//忘记安全密码展示
        resetPasswordFrom:{
          boundPhone:'',
          validateCode:'',
          loginPassword:'',
          newSecurityPassword:'',
          reNewSecurityPassword:''
        },// 忘记密码
        editPasswordFrom:{
          boundPhone:'',
          validateCode:'',
          oldSecurityPassword:'',
          newSecurityPassword:'',
          reNewSecurityPassword:''
        },

        reserveInfo:false,
        reserveTitle:"",
        reserveFrom:{
          reservedMsg:"",//预留信息
          validateCode:"",
        },
        reserveAmendIofo:false,
        regNumber:/^[0-9]*$/,
      }
    },
    created() {
      this.userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
      this.getUserInfo()
    },
    methods: {
      // 获取个人信息
      getUserInfo() {
        getSecurity().then(res => {
          if (res) {
            this.userInfo.phone = res.resultData.phoneNumber
            this.userInfo.email = res.resultData.email
            this.userInfo.passwordStatus = res.resultData.passwordStatus
            this.userInfo.reservedMsg = res.resultData.reservedMsg
            this.phone = this.userInfo.phone.replace(/^(\d{3})\d+(\d{4})$/, '$1****$2')
          }
        })
      },
      // 修改手机
      modifyPhone(){
        this.editPhoneFirst = true
        this.editPhoneFrom = {
          boundPhone: this.userInfo.phone,
          validateCode:'',
          rebindPhone:'',
          rebindValidateCode:'',
          securityPassword:''
        }
        clearInterval(this.codeShow)
        this.timeOut = 0
      },
      // 获取原验证码
      getOldCode(){
        if (this.timeOut != 0) {
        	return;
        }
        this.timeOut = 60
        getValidatecode().then(res => {
        	if (res) {
        		this.codeShow = setInterval(() => {
        			if (this.timeOut == 0) {
        				clearInterval(this.codeShow)
        			} else {
        				this.timeOut--
        			}
        		}, 1000)
        	}else{
            clearInterval(this.codeShow)
            this.timeOut = 0
          }
        })
      },
      // 获取验证码
      getCode(phone = null) {
        if (this.timeOut != 0) {
        	return;
        }
        if(!/^[1][2,3,4,5,6,7,8,9][0-9]{9}$/.test(phone)){
          this.$Message.error('请输入正确手机号')
          return
        }
        let data = {
        	phoneNumber: phone
        }
        this.timeOut = 60
        validatecode(data).then(res => {
        	if (res) {
        		this.codeShow = setInterval(() => {
        			if (this.timeOut == 0) {
        				clearInterval(this.codeShow)
        			} else {
        				this.timeOut--
        			}
        		}, 1000)
        	}else{
            clearInterval(this.codeShow)
            this.timeOut = 0
          }
        })
      },
      // 修改手机下一步
      editPhone() {
        if(!this.editPhoneFrom.validateCode){
          this.$Message.error('请输入验证码')
          return
        }
        clearInterval(this.codeShow)
        this.timeOut = 0
        this.editPhoneFirst = false
        this.editPhoneSecond = true
      },
      // 修改手机
      editPhoneTrue(){
        let data = {...this.editPhoneFrom}
        if(!data.rebindPhone){
          this.$Message.error('请输入绑定手机号')
          return
        }
        if(!/^[1][2,3,4,5,6,7,8,9][0-9]{9}$/.test(data.rebindPhone)){
          this.$Message.error('请输入正确手机号')
          return
        }
        if(data.rebindPhone === data.boundPhone){
          this.$Message.error('当前手机号码已绑定，请输入新的手机号码')
          return
        }
        if(!data.rebindValidateCode){
          this.$Message.error('请输入验证码')
          return
        }
        if(!data.securityPassword){
          this.$Message.error('请输入安全密码')
          return
        }
        data.securityPassword = md5(data.securityPassword)
        editPhone(data).then(res=>{
          if(res){
            this.$Message.success('修改成功')
            this.getUserInfo()
            this.editPhoneSecond = false
          }
        })
      },
      // 修改邮箱
      modifyEmail(){
        this.editEmailFrom = {
          boundEmail:'',
          rebindEmail:'',
          securityPassword:''
        }
        this.editEmail = true
        clearInterval(this.codeShow)
        this.timeOut = 0
      },
      //修改邮箱
      editEmailTrue(){
        let data = {...this.editEmailFrom}
        if(!data.boundEmail){
          this.$Message.error('请输入当前绑定邮箱')
          return
        }
        if(!data.rebindEmail){
          this.$Message.error('请输入要绑定的邮箱')
          return
        }
        console.log(data.rebindEmail);
        if(!/^\w+((-\w+)|(\.\w+))*@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/.test(data.rebindEmail)){
          this.$Message.error('请输入正确的邮箱账号')
          return
        }
        if(!data.securityPassword){
          this.$Message.error('请输入安全密码')
          return
        }
        data.securityPassword = md5(data.securityPassword)
        editEamil(data).then(res=>{
          if(res){
            this.$Message.success('修改成功')
            this.getUserInfo()
            this.editEmail = false
          }
        })
      },
      handlEditPassword(value) {
        this.passwordStatus = value + "安全密码";
        this.resetPasswordFrom = {
          boundPhone:this.userInfo.phone,
          validateCode:'',
          loginPassword:'',
          newSecurityPassword:'',
          reNewSecurityPassword:''
        }
        clearInterval(this.codeShow)
        this.timeOut = 0
        this.resetPassword = true;
      },
      // 重置密码
      settingPwd() {
        let data = {...this.resetPasswordFrom}
        if(!data.validateCode){
          this.$Message.error('请输入验证码')
          return
        }
        if(!data.loginPassword){
          this.$Message.error('请输入登录密码')
          return
        }
        if(!data.newSecurityPassword){
          this.$Message.error('请输入新安全密码')
          return
        }
        if(!data.reNewSecurityPassword){
          this.$Message.error('请输入确认新安全密码')
          return
        }
        if(data.newSecurityPassword.length<6 || data.reNewSecurityPassword.length<6){
          this.$Message.error('安全密码必须是六位数字')
          return
        }
        if(!this.regNumber.test(data.newSecurityPassword) || !this.regNumber.test(data.reNewSecurityPassword)){
          this.$Message.error('安全密码必须是六位数字')
          return
        }
        if(data.reNewSecurityPassword !== data.newSecurityPassword){
          this.$Message.error('新安全密码输入不一致')
          return
        }
        data.loginPassword = md5(data.loginPassword)
        data.newSecurityPassword = md5(data.newSecurityPassword)
        delete data.reNewSecurityPassword
        resetPassword(data).then(res=>{
          if(res){
            this.getUserInfo()
            this.$Message.success('设置成功')
            this.resetPassword = false;
          }
        })
      },
      // 修改密码
      modifyPassword(){
        this.editPasswordFrom = {
          boundPhone:this.userInfo.phone,
          validateCode:'',
          oldSecurityPassword:'',
          newSecurityPassword:'',
          reNewSecurityPassword:''
        }
        clearInterval(this.codeShow)
        this.timeOut = 0
        this.editPassword = true
      },
      // 修改安全密码
      editPasswordTrue(){
        let data = {...this.editPasswordFrom}
        if(!data.validateCode){
          this.$Message.error('请输入验证码')
          return
        }
        if(!data.oldSecurityPassword){
          this.$Message.error('请输入原安全密码')
          return
        }
        if(!data.newSecurityPassword){
          this.$Message.error('请输入新安全密码')
          return
        }
        if(!data.reNewSecurityPassword){
          this.$Message.error('请输入确认新安全密码')
          return
        }
        if(data.newSecurityPassword.length<6 || data.reNewSecurityPassword.length<6){
          this.$Message.error('安全密码必须是六位数字')
          return
        }
        if(!this.regNumber.test(data.newSecurityPassword) || !this.regNumber.test(data.reNewSecurityPassword)){
          this.$Message.error('安全密码必须是六位数字')
          return
        }
        if(data.reNewSecurityPassword !== data.newSecurityPassword){
          this.$Message.error('新安全密码输入不一致')
          return
        }
        data.oldSecurityPassword = md5(data.oldSecurityPassword)
        data.newSecurityPassword = md5(data.newSecurityPassword)
        delete data.reNewSecurityPassword
        editPassword(data).then(res=>{
          if(res){
            this.getUserInfo()
            this.$Message.success('修改成功')
            this.editPassword = false;
          }
        })
      },
      // 设置/修改预留信息
      reserveSet(value){
        this.reserveTitle = value + "预留信息";
        this.reserveFrom = {
          reservedMsg:"",
          validateCode:"",
        }, 
        clearInterval(this.codeShow)
        this.timeOut = 0 
        this.reserveInfo = true;     
      },
      // 设置/修改预留信息
      reservedInfoBtn(){
        let data = {...this.reserveFrom};
        if(!data.validateCode){
          this.$Message.error('请输入验证码')
          return
        }
        if(data.reservedMsg=="" || data.reservedMsg.length<4 || data.reservedMsg.length>10){
          this.$Message.error('请输入预留信息，字符长度为4-10字')
          return
        }
        reservedMsgSet(data).then(res=>{
          if(res){
            if(this.reserveTitle == "设置预留信息"){
              this.$Message.success('设置成功')
            }else{
              this.$Message.success('修改成功')
            }           
            this.getUserInfo()
            this.reserveInfo = false
            sessionStorage.setItem('reservedMsg', data.reservedMsg);
            // location.reload();
            console.log(sessionStorage.getItem('reservedMsg'))
          }
        })
      },
      // 查看预留信息
      reserveSee(){
        this.reserveAmendIofo = true;
        reservedMsgSee().then(res=>{
          if(res){
            this.reserveFrom.reservedMsg = res.resultData.reservedMsg;
          }
        })
      },
    },
  };
</script>

<style scoped>
  .shop_ul {
    width: 60px;
    height: 36px;
    padding-left: 13px;
    padding-top: 10px;
    color: #606266 !important;
    border-color: #DCDFE6 !important;
    background-color: #fff !important;
  }

  .shop_ul:hover {
    color: var(--themeColor) !important;
    border-color: var(--themeColor) !important;
  }

  .getCode {
    display: inline-block;
    width: 70px;
    margin-left: 15px;
    color: var(--themeColor);
    cursor: pointer;

  }

  .lianjie {
    display: inline-block;
    color: #008AFF;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: rgba(0, 138, 255, 1);
    position: relative;
    padding: 0 11px;
    cursor: pointer;
  }

  .tableForm {
    border-top: 1px solid #D7DAE2;
    border-left: 1px solid #D7DAE2;
    width: 100%;
    margin-top: 40px;

  }

  .tableForm td {
    border-bottom: 1px solid #D7DAE2;
    border-right: 1px solid #D7DAE2;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: rgba(102, 102, 102, 1);
    text-align: center;
    padding-top: 23px !important;
    padding-bottom: 23px !important;
    position: relative;
    text-decoration: none;
  }

  .overAccount_ul {
    overflow: hidden;
  }

  .overAccount_ul .ul_left {
    width: 320px;
    margin: 0 auto;
  }

  .overAccount_ul_title {
    height: 36px;
    font-size: 14px;
    line-height: 36px;
    /* color: #66666666; */
    text-align: left;
    margin-top: 16px;
  }
 

.addBt {
  width: 60px;
  height: 32px;
  background-color: var(--themeColor);
  border-color: var(--themeColor);
  color: #ffffff;
  font-size: 12px;
  padding: 0;
  border-radius: 5px;
}

.exitBt {
  width: 60px;
  height: 32px;
  background-color: #ffffff;
  color: var(--title);
  font-size: 12px;
  padding: 0;
  border-color: #dcdee2;
  border-radius: 5px;
}

</style>
