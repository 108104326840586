import axios from '@/utils/request'

// 系统管理-角色列表
export function getRoles(params = {}) {
  params.loading = true
	return axios({
		url: `/manager/system/roles`,
		method: 'get',
		params
	})
}
// 系统管理-角色详情
export function getRolesDetail(roleId) {
	return axios({
		url: `/manager/system/roles/${roleId}`,
		method: 'get'
	})
}
// 系统管理-编辑角色
export function setRoles(data) {
	return axios({
		url: `/manager/system/roles/${data.roleId}`,
		method: 'post',
		data
	})
}
// 系统管理-编辑角色
export function delRoles(roleId) {
	return axios({
		url: `/manager/system/roles/${roleId}`,
		method: 'delete'
	})
}
// 系统管理-员工管理
export function getUsers(params) {
	return axios({
		url: `/manager/system/users`,
		method: 'get',
		params
	})
}
// 系统管理-编辑员工
export function setUsers(data) {
	return axios({
		url: `/manager/system/users/${data.userId}`,
		method: 'post',
		data
	})
}
// 系统管理-删除员工
export function delUsers(userId) {
	return axios({
		url: `/manager/system/users/${userId}`,
		method: 'delete'
	})
}
// 系统管理-激活或冻结账户
export function setUsersStatus(data) {
	return axios({
		url: `/manager/system/users/${data.userId}/status`,
		method: 'put',
		data
	})
}

// 系统管理-登录日志
export function getLogs(params) {
	return axios({
		url: `/manager/system/logs`,
		method: 'get',
		params
	})
}
// 系统管理-查询系统配置
export function getConfig(params) {
	return axios({
		url: `/manager/system/config`,
		method: 'get',
		params
	})
}
// 系统配置-编辑系统配置
export function setConfig(data) {
	return axios({
		url: `/manager/system/config`,
		method: 'post',
		data
	})
}
// 密钥管理-查看
export function viewMchkey(data) {
	return axios({
		url: `/manager/system/mchkey`,
		method: 'post',
		data
	})
}
// 密钥管理-变更
export function setMchkey(data) {
	return axios({
		url: `/manager/system/mchkey/change`,
		method: 'post',
		data
	})
}
// 安全设置-设置/重置安全密码
export function resetPass(data) {
	return axios({
		url: `/manager/system/security/password/reset`,
		method: 'post',
		data
	})
}
// 安全设置-改绑手机号码
export function editPhone(data) {
	return axios({
		url: `/manager/system/security/phone/rebind`,
		method: 'put',
		data
	})
}
// 安全设置-改绑邮箱
export function editEamil(data) {
	return axios({
		url: `/manager/system/security/email/rebind`,
		method: 'put',
		data
	})
}
// 安全设置-设置/重置安全密码
export function resetPassword(data) {
	return axios({
		url: `/manager/system/security/password/reset`,
		method: 'post',
		data
	})
}
//  安全设置-修改安全密码
export function editPassword(data) {
	return axios({
		url: `/manager/system/security/password`,
		method: 'put',
		data
	})
}

// 渠道配置-列表
export function getChannelsData(params) {
	return axios({
		url: `/manager/system/channels`,
		method: 'get',
		params
	})
}
// 渠道配置-新增/修改
export function setChannels(data) {
	return axios({
		url: `/manager/system/channels/${data.mchChannelId}`,
		method: 'post',
		data
	})
}
// 渠道配置-删除
export function deleteChannels(mchChannelId) {
	return axios({
		url: `/manager/system/channels/${mchChannelId}`,
		method: 'delete'
	})
}
//设置或修改预留信息
export function reservedMsgSet(data) {
	return axios({
		url: `/manager/system/security/reservedMsg`,
		method: 'post',
		data
	})
}
//查看预留信息
export function reservedMsgSee(data) {
	return axios({
		url: `/manager/system/security/reservedMsgDetail`,
		method: 'post',
		data
	})
}
